import { Link, LinkProps } from '@/components/atoms/Link';
import React, { PropsWithChildren } from 'react';
import Button, { BaseButtonProps, ButtonVariants } from '../Button/Button';

export interface ButtonLinkProps
  extends Omit<BaseButtonProps, 'onClick' | 'onMouseEnter'>,
    LinkProps {
  variant?: ButtonVariants;
  href: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  target?: React.HTMLAttributeAnchorTarget;
}

const ButtonLink: React.FC<PropsWithChildren<ButtonLinkProps>> = ({
  href,
  variant = ButtonVariants.Primary,
  children,
  target,
  ...props
}) => {
  return (
    <Link href={href} target={target}>
      <Button {...props} variant={variant}>
        {children}
      </Button>
    </Link>
  );
};

export default ButtonLink;
