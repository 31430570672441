import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { SearchBarWithMenu } from '@/components/molecules/SearchBarWithMenu/SearchBarWithMenu';
import { useZipContext } from '@/context/ZipContext';
import { useSaveRecentSearchMutation } from '@/hooks/queries/recent-searches';
import useWindowSize from '@/hooks/useWindowSize';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { handleFilteredSearch } from '@/lib/searchFilters';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { FC, useState } from 'react';
import { LocationPopOutModal } from '../LocationPopOutModal/LocationPopOutModal';

export type HomeTemplateProps = {
  makes: string[];
  models: { [key: string]: string[] };
  placeholder: string;
};

export const HomePageSearchBar: FC<HomeTemplateProps> = (props) => {
  const { makes, models, placeholder } = props;
  const [input, setInput] = useState<string>('');
  const { mutateAsync: saveRecentSearch } = useSaveRecentSearchMutation();
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const { preferredZip, userAddress, customAddress, customZip } =
    useZipContext();
  const { zip: userZip, city, state, country } = userAddress || {};

  const { isMobile } = useWindowSize();

  const [locationModalOpen, setLocationModalOpen] = useState(false);

  const location =
    city !== undefined && state !== undefined ? `${city}, ${state}` : userZip;

  const handleVectorSearch = async (inp: string) => {
    await saveRecentSearch(inp);
    handleFilteredSearch({
      input: inp,
      zip: preferredZip,
    });
  };

  return (
    <>
      <div className="flex w-full flex-col" inert={searchDialogOpen}>
        <div className="mb-m flex flex-col md:w-full md:flex-row md:gap-l">
          <div className="mb-s inline-flex h-[54px] w-full md:basis-7/10">
            <SearchBarWithMenu
              input={input}
              setInput={setInput}
              models={models}
              makes={makes}
              handleVectorSearch={handleVectorSearch}
              placeholder={placeholder}
              setShowOptions={setShowOptions}
              showOptions={showOptions}
              onFocus={
                isMobile
                  ? () => {
                      setSearchDialogOpen(true);
                    }
                  : undefined
              }
            />
          </div>
          <div className="flex h-[54px] w-full md:mb-0 md:basis-3/10 ml:h-full">
            <Button
              aria-label="Search EVs"
              variant={ButtonVariants.Tertiary}
              analyticsEvent={{
                ...GOOGLE_TRACK_INFO.inventoryHomePageButton,
                pageTarget: 'search',
              }}
              onClick={() => {
                if (isMobile) {
                  setSearchDialogOpen(true);
                } else {
                  handleVectorSearch(input);
                }
              }}
            >
              Search
            </Button>
          </div>
        </div>

        <div className="flex w-full justify-between">
          {city && state && country && (
            <div
              role="button"
              className="fit-content flex cursor-pointer items-center"
              onClick={() => setLocationModalOpen(true)}
            >
              <MapPinIcon
                stroke="#FFFFFF"
                className="pr-s"
                width={24}
                height={24}
              />
              <span className="text-body1Medium text-neutral-0">
                {customZip ? customAddress || customZip : location || userZip}
              </span>
              <LocationPopOutModal
                open={locationModalOpen}
                initialZipCode={preferredZip}
                shouldStayOrigin={true}
                persistZip={true}
                close={() => {
                  setLocationModalOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </div>
      {searchDialogOpen && (
        <div className="fixed inset-0 z-50 h-screen w-full bg-neutral-100">
          <SearchBarWithMenu
            input={input}
            limitSuggestions={false}
            setInput={setInput}
            models={models}
            makes={makes}
            handleVectorSearch={async (searchInput) => {
              await handleVectorSearch(searchInput);
              setSearchDialogOpen(false);
              setInput('');
            }}
            setShowOptions={setSearchDialogOpen}
            showOptions={searchDialogOpen}
            searchBarVariant="dialog"
          />
        </div>
      )}
    </>
  );
};
