import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';

export type LinkProps = Omit<NextLinkProps, 'prefetch'> &
  Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'className' | 'target' | 'rel'>;

/** This component is the same as Next.js' `<Link>` component, but it doesn't prefetch pages in the background to improve performance of page loads. */
export const Link = ({ children, ...rest }: PropsWithChildren<LinkProps>) => (
  <NextLink {...rest} prefetch={false}>
    {children}
  </NextLink>
);
