import { ResponsiveMargin } from '@/components/atoms/legacy/ResponsiveMargin/ResponsiveMargin';
import { GVECalculator } from '@/components/molecules/GVECalculator/GVECalculator';
import { SwiperCarousel } from '@/components/molecules/SwiperCarousel/SwiperCarousel';
import { PaymentCalculator } from '@/components/organisms/PaymentCalculator/PaymentCalculator';
import useWindowSize from '@/hooks/useWindowSize';
import { FC, useState } from 'react';

const PAYMENT_CALCULATOR_COPY = {
  header: 'Calculate your monthly vehicle payment',
  subHeader: 'Discover hundreds of EVs for sale within your budget.',
};
const GVE_CALCULATOR_COPY = {
  header: 'EV vs Gas comparison',
  subHeader:
    'Find out why EV ownership has cheaper running costs than a gasoline-powered vehicle.',
};

export const HomePageCalculatorSection: FC = () => {
  const { isMobile } = useWindowSize();

  const [expanded, setExpanded] = useState(true);
  const [allowTouchMove, setAllowTouchMove] = useState(true);
  const [page, setPage] = useState(2);
  const [salePrice, setSalePrice] = useState(40000);

  const slides = [
    <div
      key={'payment-calc'}
      className={`flex h-full ${page % 2 === 0 ? 'opacity-100' : 'opacity-60'} px-s pb-xl transition-opacity duration-500 ease-in-out`}
    >
      <PaymentCalculator
        salePrice={salePrice}
        setSalePrice={setSalePrice}
        expanded={expanded}
        setExpanded={setExpanded}
        type="homepage"
        initialMode="Finance"
      />
    </div>,
    <div
      key={'gve-calc'}
      className={`flex h-full ${page % 2 === 1 ? 'opacity-100' : 'opacity-60'} pb-xl transition-opacity duration-500 ease-in-out`}
    >
      <GVECalculator
        expanded={expanded}
        setExpanded={setExpanded}
        variant={'homepage'}
        setAllowTouchMove={setAllowTouchMove}
      />
    </div>,
  ];

  return (
    <section className="bg-gradient-to-r from-lightTheme-start to-lightTheme-end py-3xl m:pb-xl m:pt-[80px]">
      <div className="flex w-full flex-col gap-xl m:flex-row">
        <ResponsiveMargin variant="template" right={isMobile}>
          <div className="relative flex h-[80%] w-full flex-col items-center justify-center gap-s pb-m">
            <div className="absolute left-0 top-0 m:opacity-0">
              <div className="flex w-full text-h2SemiBold text-brandMidnight">
                {page % 2 === 0
                  ? PAYMENT_CALCULATOR_COPY.header
                  : GVE_CALCULATOR_COPY.header}
              </div>
              <div className="flex w-full text-body1Regular text-brandMidnight">
                {page % 2 === 1
                  ? PAYMENT_CALCULATOR_COPY.subHeader
                  : GVE_CALCULATOR_COPY.subHeader}
              </div>
            </div>
            {/* at mobile 
                this block is used to set the height of the title carousel (PAYMENT_CALCULATOR_COPY is longer than GVE_CALCULATOR_COPY)
                this prevents the title from jumping when the carousel switches slides
            */}
            <div className="opacity-0 m:opacity-100">
              <div className="flex w-full text-h2SemiBold text-brandMidnight">
                {PAYMENT_CALCULATOR_COPY.header}
              </div>
              <div className="flex w-full text-body1Regular text-brandMidnight">
                {PAYMENT_CALCULATOR_COPY.subHeader}
              </div>
            </div>
          </div>
        </ResponsiveMargin>

        <div className="flex w-full pb-xl m:w-8/12">
          <SwiperCarousel
            slidesPerView={isMobile ? 1.12 : 1.15}
            spaceBetween={isMobile ? 10 : 30}
            loop={true}
            slides={slides.concat(slides)} // Swiper needs slides length needs to be > 2 to allow for infinite loop
            updateCurrentIndex={setPage}
            selectedIndex={page}
            centeredSlides={isMobile}
            navigation={!isMobile}
            allowTouchMove={allowTouchMove}
            navigationWrapper={
              <ResponsiveMargin variant="template" left={false} />
            }
            dots={true}
            dotAmount={2}
          />
        </div>
      </div>

      <ResponsiveMargin variant="template">
        <span className="flex text-microLight text-neutral-600">
          The finance calculator estimates are for convenience only and are not
          a finance offer or credit guarantee. The estimate excludes taxes,
          title, registration, vehicle sale, and financing fees. Costs may vary
          within states. Comparison vehicles are based on average EV and
          gasoline consumption rates.
        </span>
      </ResponsiveMargin>
    </section>
  );
};
