import { ResponsiveMargin } from '@/components/atoms/legacy/ResponsiveMargin/ResponsiveMargin';
import { HeroSection } from '@/components/organisms/HeroSection';
import { HomePageCalculatorSection } from '@/components/organisms/HomePageCalculatorSection/HomePageCalculatorSection';
import { HomePageCompareCarousel } from '@/components/organisms/HomePageCompareCarousel//HomePageCompareCarousel';
import { HomePageShopType } from '@/components/organisms/HomePageShopType/HomePageShopType';
import { FC } from 'react';
import { HomePageRecentArticles } from '../../organisms/HomePageRecentArticles';

export interface HomeTemplateProps {
  makes: string[];
  models: { [key: string]: string[] };
}

export const HomeTemplate: FC<HomeTemplateProps> = ({ makes, models }) => {
  return (
    <div className="relative w-full">
      <section className="bg-neutral-100 md:py-[36px]">
        <ResponsiveMargin variant="template">
          <HeroSection makes={makes} models={models} />
        </ResponsiveMargin>
      </section>

      <ResponsiveMargin variant="template">
        <HomePageCompareCarousel />
      </ResponsiveMargin>

      <ResponsiveMargin variant="template">
        <HomePageShopType makes={makes} />
      </ResponsiveMargin>

      <div className="pt-xl">
        <HomePageCalculatorSection />
      </div>

      <HomePageRecentArticles />
    </div>
  );
};
