import useWindowSize from '@/hooks/useWindowSize';
import { SEOMetaTags } from '@/lib/seo/seoTags';
import Image from 'next/image';
import { FC } from 'react';
import { HomePageSearchBar } from '../HomePageSeachBar/HomePageSearchBar';

export type HeroSectionProps = {
  makes: string[];
  models: { [key: string]: string[] };
};

export const HeroSection: FC<HeroSectionProps> = (props) => {
  const { isMobile, isTablet, isDesktop } = useWindowSize();

  return (
    <div className="relative w-full">
      <div className="absolute inset-0 z-0">
        {isTablet || isDesktop ? (
          <video
            autoPlay
            playsInline
            loop
            muted
            className="h-full w-full rounded-medium object-cover"
            aria-label="Home page hero video"
            poster={`https://homepage-hero-videos.s3.amazonaws.com/hero-poster-${isDesktop ? 'desktop' : 'tablet'}-2.webp`}
          >
            <source
              src="https://homepage-hero-videos.s3.amazonaws.com/hero-video-mobile-2.mp4"
              type="video/mp4"
              media="(max-width: 767px)"
            />
            <source
              src="https://homepage-hero-videos.s3.amazonaws.com/hero-video-tablet-2.mp4"
              type="video/mp4"
              media="(max-width: 1023px)"
            />
            <source
              src="https://homepage-hero-videos.s3.amazonaws.com/hero-video-desktop-2.mp4"
              type="video/mp4"
              media="(min-width: 1024px)"
            />
            Your browser does not support the video tag.
          </video>
        ) : (
          <Image
            src="https://homepage-hero-videos.s3.amazonaws.com/hero-poster-mobile-2.webp"
            alt="Home page hero image"
            fill
            priority
            className="rounded-medium object-cover"
          />
        )}
      </div>
      <div className="relative flex w-full flex-col items-center justify-center px-l py-[42px] md:items-start md:px-[40px] md:py-[58px]">
        <h1 className="mb-s text-center text-h2SemiBold text-white md:text-h1SemiBold">
          EV for all
        </h1>
        <h2 className="mb-xl text-center text-body1Regular font-normal text-white md:mb-[52px] md:text-h2Regular">
          {SEOMetaTags.homePage.h2Tag}
        </h2>
        <HomePageSearchBar
          makes={props.makes}
          models={props.models}
          placeholder={isMobile ? 'Search' : 'Search by make, model or keyword'}
        />
      </div>
    </div>
  );
};
