import clsx from 'clsx';
import { FC } from 'react';
import { BodyTypeIcon, BodyTypeIconId } from '../../atoms/BodyTypeIcon';

interface BodyTypeItemProps {
  bodyType: BodyTypeIconId;
  'aria-label': string;
  onClick?: () => void;
  label?: string;
}

const BodyTypeItem: FC<BodyTypeItemProps> = ({
  bodyType,
  'aria-label': ariaLabel,
  onClick,
  label,
}) => {
  return (
    <div
      className={clsx('flex flex-col', {
        'cursor-pointer': onClick,
      })}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      <div className="relative flex">
        <BodyTypeIcon bodyType={bodyType} />
      </div>
      {label && (
        <div className="flex w-full justify-center pt-s text-body1Medium text-neutral-800">
          {label}
        </div>
      )}
    </div>
  );
};

const CurrentBodyTypes: {
  icon: BodyTypeIconId;
  label: string;
  value: string;
}[] = [
  { icon: 'Sedan', label: 'Sedan', value: 'Sedan' },
  { icon: 'Suv', label: 'SUVs', value: 'SUV' },
  { icon: 'Hatchback', label: 'Hatchbacks', value: 'Hatchback' },
  { icon: 'Minivan', label: 'Mini van', value: 'Minivan' },
  { icon: 'Wagon', label: 'Wagon', value: 'Wagon' },
  { icon: 'Truck', label: 'Trucks', value: 'Truck' },
];

export const ShopByBodyType: FC<{
  onClick: (bodyType: string) => void;
}> = ({ onClick }) => {
  return (
    <div className="flex flex-col py-3xl m:py-[64px]">
      <div className="flex justify-center pb-xl m:pb-[55px]">
        <div className="text-h2Regular text-neutral-900">Shop by body type</div>
      </div>
      <div className="flex w-full justify-center">
        <div className="grid w-full grid-cols-2 gap-l m:flex m:w-fit-content m:justify-between m:gap-m">
          {CurrentBodyTypes.map((bodyTypeObj) => {
            return (
              <div
                key={`${bodyTypeObj.value}`}
                className="flex justify-center p-l m:py-0"
              >
                <BodyTypeItem
                  label={bodyTypeObj.label}
                  bodyType={bodyTypeObj.icon}
                  aria-label={`search for ${bodyTypeObj.value}`}
                  onClick={() => onClick(bodyTypeObj.value)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
