import {
  HomeTemplate,
  HomeTemplateProps,
} from '@/components/template/HomeTemplate/HomeTemplate';
import { getSearchMakesAndModels } from '@/lib/getSearchMakesAndModels';
import { SEOMetaTags } from '@/lib/seo/seoTags';
import { BasePageProps } from '@/types/page';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { Organization, WithContext } from 'schema-dts';

export default function Home(props: HomeTemplateProps) {
  const organizationSchema: WithContext<Organization> = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'EV.com',
    url: 'https://ev.com/',
    logo: 'https://ev.com/images/logo_us.svg',
    description:
      'Discover the perfect blend of innovation and sustainability with new & used electric cars for sale on EV.com. Drive into the future today',

    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+1 647-321-1111',
      email: 'hi@ev.com',
    },

    address: {
      '@type': 'PostalAddress',
      streetAddress: '1317, 447 Broadway',
      addressLocality: 'New York',
      addressRegion: 'New York',
      postalCode: '10013',
      addressCountry: 'US',
    },

    sameAs: [
      'https://www.linkedin.com/company/ev-com/',
      'https://twitter.com/EVcom',
      'https://www.facebook.com/ev.com.official',
      'https://www.youtube.com/@evdotcom',
      'https://www.tiktok.com/@ev.com',
      'https://www.instagram.com/evcom/',
    ],

    foundingDate: '1994-07-26',
    founders: [
      {
        '@type': 'Person',
        name: 'Joel Li',
        url: 'https://www.linkedin.com/in/joelli-ev-com/',
      },
    ],
  };
  return (
    <main>
      <Head>
        <script type="application/ld+json">
          {JSON.stringify(organizationSchema)}
        </script>
      </Head>
      <HomeTemplate {...props} />
    </main>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const autoCompleteResults = await getSearchMakesAndModels();

  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common'])),
      makes: autoCompleteResults.searchMakes,
      models: autoCompleteResults.searchModels,
      metadata: {
        title: SEOMetaTags.homePage.title,
        description: SEOMetaTags.homePage.description,
      },
    } satisfies BasePageProps<HomeTemplateProps>,
    revalidate: 60,
  };
};
