import { Link } from '@/components/atoms/Link';
import { Skeleton } from '@/components/atoms/Skeleton';
import { BlogPreview } from '@/types/blog';
import clsx from 'clsx';
import Image from 'next/image';

interface MinimalArticleCardProps {
  layout?: 'row' | 'column';
  size?: 'small' | 'medium';
}

interface ArticleCardProps extends MinimalArticleCardProps {
  article: BlogPreview;
}

export function ArticleCardSkeleton({
  layout = 'column',
  size = 'medium',
}: MinimalArticleCardProps) {
  const isRow = layout === 'row';
  const showExcerpt = size === 'medium';
  let imageHeightClassName = 'aspect-[16/9]';
  if (isRow) {
    imageHeightClassName = size === 'small' ? 'h-[122px]' : 'h-[190px]';
  }
  return (
    <div
      className={clsx(
        'w-full overflow-hidden rounded-large bg-white',
        isRow && size === 'small' && 'h-[122px]',
        isRow && size === 'medium' && 'h-[190px]',
        isRow ? 'grid grid-cols-1 md:grid-cols-2' : 'flex flex-col gap-4'
      )}
    >
      {/* Image skeleton */}
      <div
        className={clsx(
          `${imageHeightClassName} relative overflow-hidden`,
          isRow
            ? 'w-full md:w-auto'
            : 'aspect-[16/9] min-h-[180px] md:min-h-[220px]'
        )}
      >
        <Skeleton className="h-full w-full" />
      </div>

      <div
        className={clsx(
          'flex flex-col justify-center',
          isRow && size === 'small' && 'p-3',
          isRow && size === 'medium' && 'p-4',
          !isRow && 'gap-2 p-4'
        )}
      >
        {/* Category skeleton */}
        <Skeleton
          className={clsx(
            'h-4 w-16',
            isRow && size === 'small' && 'mb-1 h-3 w-12',
            isRow && size === 'medium' && 'mb-2 w-14',
            !isRow && 'mb-2'
          )}
        />

        {/* Title skeleton */}
        <div
          className={clsx(
            'space-y-2',
            isRow && size === 'small' && 'space-y-1'
          )}
        >
          <Skeleton
            className={clsx(
              'h-6 w-full',
              isRow && size === 'small' && 'h-4',
              isRow && size === 'medium' && 'h-5',
              !isRow && 'h-7'
            )}
          />
          <Skeleton
            className={clsx(
              'h-6 w-4/5',
              isRow && size === 'small' && 'h-4',
              isRow && size === 'medium' && 'h-5',
              !isRow && 'h-7'
            )}
          />
        </div>

        {/* Excerpt skeleton */}
        {showExcerpt && (
          <div
            className={clsx(
              'mt-2 space-y-2',
              isRow && size === 'medium' && 'mt-2',
              !isRow && 'mt-3'
            )}
          >
            <Skeleton
              className={clsx(
                'h-4 w-full',
                isRow && size === 'medium' && 'h-3'
              )}
            />
            <Skeleton
              className={clsx('h-4 w-4/5', isRow && size === 'medium' && 'h-3')}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export function ArticleCard({
  layout = 'column',
  size = 'medium',
  article,
}: ArticleCardProps) {
  const { excerpt, title, categories, image, slug } = article;
  const isRow = layout === 'row';
  let imageHeightClassName = 'aspect-[16/9]';
  if (isRow) {
    imageHeightClassName = size === 'small' ? 'h-[122px]' : 'h-[190px]';
  }

  const showExcerpt = size === 'medium' && !!excerpt;

  return (
    <Link
      href={`/news/${slug}`}
      className={clsx(
        'group relative w-full overflow-hidden rounded-lg bg-white',
        isRow ? 'grid grid-cols-1 md:grid-cols-2' : 'flex flex-col'
      )}
    >
      {/* Image wrapper with aspect ratio or fixed height */}
      {!!image && (
        <div
          className={clsx(
            `${imageHeightClassName} relative overflow-hidden`,
            isRow
              ? 'w-full md:w-auto'
              : 'aspect-[16/9] min-h-[180px] md:min-h-[220px]'
          )}
        >
          <Image
            src={image}
            alt="Article image"
            fill
            className="object-cover"
            sizes={
              isRow
                ? '(max-width: 768px) 100vw, 50vw'
                : '(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw'
            }
          />
        </div>
      )}
      <div className="flex flex-col p-l">
        {categories.length > 0 && (
          <div>
            <span className="text-body2Light uppercase text-neutral-800">
              {categories.join(', ')}
            </span>
          </div>
        )}
        <p
          className={clsx(
            'text-body1Medium text-black group-hover:text-blue-medium',
            {
              'line-clamp-3': size === 'small',
              'line-clamp-4 text-body1Medium': size === 'medium',
              'mb-s': showExcerpt,
            }
          )}
        >
          {title}
        </p>
        {showExcerpt && (
          <p className="line-clamp-2 text-body1Light text-black group-hover:text-blue-dark">
            {excerpt}
          </p>
        )}
      </div>
    </Link>
  );
}
