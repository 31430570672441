import { Link, LinkProps } from '@/components/atoms/Link';
import React, { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';
import TextButton, {
  TextButtonProps,
  TextButtonVariants,
} from '../TextButton/TextButton';

export interface ButtonLinkProps
  extends Omit<TextButtonProps, 'onClick' | 'onMouseEnter'>,
    LinkProps {
  target?: HTMLAttributeAnchorTarget;
  onClick?: () => void;
  onMouseEnter?: () => void;
}

const ButtonLink: React.FC<PropsWithChildren<ButtonLinkProps>> = ({
  href,
  variant = TextButtonVariants.Default,
  children,
  target,
  ...props
}) => {
  return (
    <Link
      aria-label={`link to ${href}`}
      href={href}
      target={target}
      rel={props.rel}
    >
      <TextButton {...props} variant={variant}>
        {children}
      </TextButton>
    </Link>
  );
};

export default ButtonLink;
