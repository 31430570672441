import { CarouselDirectionButton } from '@/components/atoms/CarouselButton/CarouselDirectionButton';
import { RotatorDot } from '@/components/atoms/RotatorDot/RotatorDot';
import { Direction } from '@/types/enums';
import clsx from 'clsx';
import React, { useCallback, useEffect, useRef, type JSX } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

export interface SwiperCarouselProps {
  slides: JSX.Element[];
  selectedIndex: number;
  updateCurrentIndex?: (index: number) => void;
  navigation?: boolean;
  navigationWrapper?: React.ReactElement<{ children: React.ReactNode }>;
  dots?: boolean;
  dotAmount?: number;
  loop?: boolean; // Swiper needs slides length needs to be > 2 to allow for infinite loop
  slidesPerView?: number;
  spaceBetween?: number;
  centeredSlides?: boolean;
  allowTouchMove?: boolean;
}

export const SwiperCarousel: React.FC<SwiperCarouselProps> = ({
  slides,
  selectedIndex,
  updateCurrentIndex = () => {},
  slidesPerView = 1,
  spaceBetween = 0,
  centeredSlides = false,
  loop = false,
  navigationWrapper = <></>,
  navigation = false,
  dots = false,
  dotAmount,
  allowTouchMove = true,
}) => {
  const swiperRef = useRef<SwiperClass | null>(null);

  const slidesLength = dotAmount || slides.length;

  const handleSlideChange = useCallback(
    (swiper: SwiperClass) => {
      updateCurrentIndex(swiper.realIndex);
    },
    [updateCurrentIndex]
  );

  const handleSlideNext = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  }, []);
  const handleSlidePrev = useCallback(() => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  }, []);

  const handleSlideTo = useCallback(
    (index: number) => {
      if (swiperRef.current) {
        if (loop) {
          swiperRef.current.slideToLoop(index);
        } else {
          swiperRef.current.slideTo(index);
        }
      }
    },
    [loop]
  );

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.allowTouchMove = allowTouchMove;
    }
  }, [allowTouchMove]);

  return (
    <div
      className={clsx('relative flex h-full w-full flex-col', {
        'pb-xl': navigation,
      })}
    >
      <div className="flex h-full">
        <Swiper
          loop={loop}
          slidesPerView={slidesPerView}
          spaceBetween={spaceBetween}
          centeredSlides={centeredSlides}
          initialSlide={selectedIndex}
          onSlideChange={handleSlideChange}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide
              key={`slide-${index}`}
              className="flex w-full justify-center"
            >
              {slide}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {(navigation || dots) &&
        React.cloneElement(
          navigationWrapper,
          {},
          <div className="relative flex h-full pb-xl pt-l m:pb-l">
            {dots && (
              <div className="absolute left-[50%] top-[45%] flex items-center justify-end space-x-[3px]">
                {slides.slice(0, slidesLength).map((_, index) => (
                  <RotatorDot
                    key={`dot-${index}`}
                    edge={true}
                    active={selectedIndex % slidesLength === index}
                    aria-label={`slide ${index}`}
                    onClick={() => {
                      if (selectedIndex !== index) {
                        handleSlideTo(index);
                      }
                    }}
                  />
                ))}
              </div>
            )}
            {navigation && (
              <div className="flex h-full w-full items-center justify-end gap-m">
                <div className="prev-arrow" onClick={handleSlidePrev}>
                  <CarouselDirectionButton
                    direction={Direction.Left}
                    onClick={() => handleSlidePrev()}
                  />
                </div>
                <div className="next-arrow" onClick={() => handleSlideNext()}>
                  <CarouselDirectionButton
                    direction={Direction.Right}
                    onClick={() => handleSlideNext()}
                  />
                </div>
              </div>
            )}
          </div>
        )}
    </div>
  );
};
