import { ButtonVariants } from '@/components/atoms/Button/Button';
import ButtonLink from '@/components/atoms/ButtonLink/ButtonLink';
import {
  ArticleCard,
  ArticleCardSkeleton,
} from '@/components/organisms/ArticleCard';
import { useInView } from 'react-intersection-observer';
import { useArticles } from '../../../hooks/queries/articles/useArticles';
import useWindowSize from '../../../hooks/useWindowSize';
import TextButtonLink from '../../atoms/TextButtonLink/TextButtonLink';

export const HomePageRecentArticles = () => {
  const { ref, inView } = useInView();
  const { isML } = useWindowSize();

  const { data, isFetching, isError, isFetched } = useArticles({
    queryKey: ['recentArticles'],
    limit: 3,
    enabled: inView,
  });

  const renderContent = () => {
    if (isError) {
      return (
        <p className="text-body1Light">
          There was a problem showing articles. Please refresh and try again.
        </p>
      );
    }

    if (isFetching) {
      return (
        <div className="flex flex-col gap-m bg-white p-l ml:flex-row">
          <div className="grid grid-cols-1 gap-l md:grid-cols-2 ml:grid-cols-3">
            {[...Array(3)].map((_, index) => (
              <ArticleCardSkeleton key={index} />
            ))}
          </div>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 gap-l md:grid-cols-2 ml:grid-cols-3">
        {data &&
          data.map((article) => (
            <div key={article.id} className="flex flex-1">
              <ArticleCard article={article} size={isML ? 'medium' : 'small'} />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div
      className="flex flex-col gap-[12px] bg-neutral-200 px-l py-[80px] ml:px-[60px] lg:py-[76px]"
      ref={isFetched ? undefined : ref}
    >
      {!isError && (
        <div className="flex flex-row justify-between">
          <h4 className="text-h4SemiBold text-brandMidnight">Recent news</h4>
          <div className="hidden ml:block">
            <TextButtonLink href="/news" aria-label="Read more articles">
              Read more
            </TextButtonLink>
          </div>
        </div>
      )}
      {renderContent()}
      {isFetched && !!data && (
        <div className="block ml:hidden">
          <ButtonLink
            href="/news"
            variant={ButtonVariants.Secondary}
            aria-label="Read more articles"
          >
            Read more
          </ButtonLink>
        </div>
      )}
    </div>
  );
};
